.subscription {
    position: relative;
    margin-bottom: 20px;
    // margin-top: 22px;

    &::after {
        top: 50%;
        content: "";
        position: absolute;
        background-color: #fff;
        height: 300px;
        width: 100%;
        transform: translateY(-50%);
        z-index: -2;
    }
}


.title {
    text-align: center;

    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
        text-align: center;

        /* Text style dark */

        color: #393939;

        margin-bottom: 24px;
    }
}

.img {
    width: 635px;
    height: 450px;
    margin-bottom: -100px;
}

.container {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    padding: 0 15px;
    overflow: hidden;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.camping {
    font-family: 'Montserrat';
    padding-top: 32px;
    padding-bottom: 20px;
    color: #393939;
}


.titleSection {
    text-align: left;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    /* Text style dark */

    color: #393939;
    margin-bottom: 24px;
    padding: 0 10px;
}

.paragraphSection {
    text-align: left;
    font-weight: 300;
    font-size: 20px;
    line-height: 140%;
    /* or 28px */
    max-width: 861px;


    /* Text style dark */

    color: #393939;
    margin-bottom: 54px;
    padding: 0 10px;
}

.blue {
    color: #516695;
}


.suitableCamping {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}



.suitableCampingTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 24px;
}

.suitableCampingList {}

.li {
    font-weight: 300;
    font-size: 20px;
    line-height: 140%;
    padding-left: 40px;
    color: #000000;
    position: relative;

    &::before {
        content: '';
        background-image: url('../../../../../../public/campingSvg.svg');
        position: absolute;
        left: 0;
        width: 24px;
        height: 24px;
    }
}


.li+.li {
    margin-top: 16px;
}

.campingFooter {
    margin-top: 52px;
}

.titleSectionFooter {
    @extend .titleSection;
    max-width: 834px;
}

.bold {
    // font-weight: bold;
    // color: #000000;
    font-weight: 500;
}

@media (max-width: 1300px) {
    .suitableCampingList {
        margin-left: 30px;
    }

    .suitableCamping {
        margin-top: 40px;
        justify-content: center;
        row-gap: 30px;
    }
}


@media (max-width: 1024px) {
    .img {
        display: none;
    }

    .subscription {
        margin-top: 80px;
        // padding: 0 20px;
    }
}

@media (max-width: 710px) {
    .suitableCampingImg {
        width: 600px;
        height: auto;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

@media (max-width: 620px) {
    .suitableCampingImg {
        width: 500px;
    }

}

@media (max-width: 590px) {
    .titleSection {
        font-size: 21px;
        line-height: 23px;
        margin-bottom: 9px;
    }

    .paragraphSection {
        font-size: 18px;
        max-width: 861px;
        margin-bottom: 54px;
    }
}


@media (max-width: 500px) {
    .title {
        h3 {
            font-size: 26px;
        }
    }
}

@media (max-width: 520px) {
    .suitableCampingImg {
        width: 450px;
    }
}

@media (max-width: 465px) {
    .suitableCampingImg {
        width: 423px;
    }
}

@media (max-width: 440px) {
    .suitableCampingImg {
        width: 385px;
    }

    .suitableCampingList {
        margin-left: 5px;
    }
}



@media (max-width: 425px) {
    .titleSection {
        font-size: 19px;
    }

    .paragraphSection {
        font-size: 13px;
        max-width: 861px;
        margin-bottom: 32px;
    }

    .suitableCampingTitle {
        font-size: 19px;
        line-height: 134%;
        margin-bottom: 14px;
    }

    .subscription {

        &::after {
            height: 200px;
        }
    }

    .camping {
        padding-bottom: 10px;
    }

    .campingFooter {
        margin-top: 25px;
    }


    .titleSectionFooter {
        font-size: 12px;
    }
}

@media (max-width: 425px) {
    .title {
        h3 {
            font-size: 22px;
        }
    }
}

@media (max-width: 390px) {
    .suitableCampingImg {
        width: 327px;
    }

    .titleSectionFooter {
        font-size: 15px;
    }
}

@media (max-width: 375px) {
    .subscription {

        &::after {
            height: 170px;
        }
    }

    .title {
        h3 {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .button {
        width: 326px;

    }
}

@media (max-width: 350px) {
    .suitableCampingTitle {
        font-size: 21px;
    }

    .li {
        font-size: 12px;
    }
}

@media (max-width: 330px) {
    .title {
        h3 {
            font-size: 16px;
            line-height: 21px;
        }
    }

    .button {
        width: 282px;

    }
}