.img {
    width: 320px;
    height: 320px;
    min-width: 320px;
    min-height: 320px;

    img {
        width: 100%;
        height: 100%;
    }
}

.title {
    font-family: 'Montserrat';
    color: #393939;

    width: 320px;
    padding: 20px;
    background-color: #fff;

    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }

    h4 {
        margin-top: 8px;
        margin-bottom: 8px;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 140%;
        /* or 22px */


        /* Text style dark */

    }
}

.container {
    margin: 0 auto;
    max-width: 1380px;
    padding: 0 15px;
    min-width: 320px;
}

.orders {
    display: flex;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 40px;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 425px) {
    .orders {
        justify-content: center;
    }
}