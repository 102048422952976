.container {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    padding: 0 15px;
    text-align: center;
}

.Contacts {
    margin-top: 105px;


    .title {

        h1 {
            font-family: 'Arkhip';
            font-style: normal;
            font-weight: 400;
            font-size: 52px;
            line-height: 62px;

            /* Text style dark */

            color: #393939;
            margin-bottom: 16px;
        }

        h3 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 24px;
            text-align: center;

            /* Text style dark */
            color: #393939;
            margin-bottom: 56px;
        }
    }

    margin-top: 56px;
}


.wrapperItem {
    display: flex;
    justify-content: space-between;
    margin-bottom: 155px;
    padding: 0 20px;
    flex-wrap: wrap;
}

.item {
    width: 360px;

    .img {
        margin-bottom: 23px;
    }

    h4 {
        margin-bottom: 16px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        /* identical to box height */


        /* Text style dark */

        color: #393939;
    }

    h5 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 140%;
        /* or 28px */

        text-align: center;

        /* Text style dark */

        color: #393939;
    }
}

@media (max-width: 1126px) {
    .wrapperItem {
        row-gap: 40px;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .wrapperItem {
        flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: center;
        gap: 32px;

    }

    .item {
        margin: 0 20px;
    }
}

@media (max-width:425px) {
    .wrapperItem {
        flex-wrap: wrap;
        justify-content: center;
        gap: 32px;
    }
}