@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;500;600&display=swap');

$bc_color: #516695;
$primary_color: #E6EDF2;

body {
    background-color: $primary_color;
}

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin: 0;
}