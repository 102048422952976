.social {
    ul {
        display: flex;

        li {
            list-style: none;
        }

        li+li {
            margin-left: 40px;
        }
    }
}

.contat {
    //   width: 400px;
}

.bottomWrapperItem {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 73px;
    row-gap: 88px;
}

.bottomItem {
    h3 {

        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 140%;
        /* identical to box height, or 45px */


        color: #515355;
        margin-bottom: 20px;
    }
}

.phone {
    font-family: 'Montserrat';
}

.map {
    width: 900px;
    height: 448px;
}

@media (max-width: 1024px) {
    .map {
        width: 100%;
        height: 350px;
    }

    .contat {
        width: 694px;
    }
}

@media (max-width: 920px) {
    .bottomWrapperItem {
        column-gap: 170px;
    }

}

@media (max-width: 425px) {
    .contat {
        width: 100%;
    }

    .bottomWrapperItem {
        margin-top: 29px;
        column-gap: 73px;
        row-gap: 25px;
    }

    .bottomItem {
        h3 {
            font-size: 23px;
            line-height: 140%;
            color: #515355;
            margin-bottom: 10px;
        }


    }

    .phone {
        font-size: 14px;
    }
}

@media (max-width: 375px) {
    .bottomWrapperItem {
        row-gap: 35px;
    }
}

@media (max-width: 320px) {
    .bottomWrapperItem {
        h3 {
            font-size: 23px;
        }
    }

    .phone {
        font-size: 12px;
    }
}