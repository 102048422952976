.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.wrapperCard {
    max-width: 560px;
    max-height: 250px;
    position: relative;
    margin: 0;
}

.cardImage {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: relative;
    background-color: rgb(135, 132, 132);
}

.wrapperCard:hover>.cardDescription {
    opacity: 1;
    transform: translateY(0);

}

.image {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
}

.cardImageTitle {
    position: absolute;
    bottom: 24px;
    left: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;

    /* White */

    color: #FFFFFF;

}

.cardDescription {
    transition: all 0.25s;
    transform: translateY(-30px);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 24px;
}

.cardDescriptionParagraph {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Montserrat';

    /* White */

    color: #FFFFFF;
    margin-top: 46px;
    margin-bottom: 16px;
    text-align: left;
}

.cardDescriptionReadMore {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-decoration-line: underline;

    /* Accent 1 */
    cursor: pointer;

    color: #FFCD00;
}
.wrapperCard:hover .cardImageTitle {
    opacity: 0;
}
@media (max-width:1024px) {
    .wrapper {
        justify-content: center;
    }

    .wrapperCard {
        min-width: 952px;
        min-height: 360px;
    }

    .cardDescriptionParagraph {
        font-size: 23px;
        line-height: 65px;
        margin-top: 54px;
        margin-bottom: 22px;
    }


}

@media (max-width: 768px) {
    .wrapperCard {
        min-width: 692px;
        min-height: 300px;
    }

    .cardDescriptionParagraph {
        font-size: 18px;
        line-height: 46px;
    }
}

@media (max-width: 425px) {
    .listItem {
        font-size: 14px;
        line-height: 15px;
    }

    .titleDescription {
        font-size: 14px;
    }

    .wrapperCard {
        min-width: 0;
        min-height: 0;
        max-width: 390px;
    }

    .cardDescriptionParagraph {
        font-size: 13px;
        line-height: 21px;
        margin-top: 18px;
        margin-bottom: 14px;
    }
}

@media (max-width: 375px) {
    .wrapperCard {
        max-width: 93%;
    }

    .cardDescriptionParagraph {
        font-size: 11px;
    }
}

@media (max-width: 320px) {
    .cardDescriptionParagraph {
        font-size: 9px;
        line-height: 16px;
    }
}