.MainPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 880px;
    background-image: url(../../../assets/image/mainPicture5.jpg);
    background-color: rgba(63, 63, 63, 0.432);
    background-blend-mode: multiply;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .title {
        text-align: center;
        color: #fff;
        width: 770px;

        .titleText {
            font-family: 'Arkhip';
            font-style: normal;
            font-weight: 400;
            font-size: 64px;
            line-height: 77px;
        }

        p {
            margin-top: 32px;
            margin-bottom: 40px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 140%;
            font-family: 'Montserrat';

        }
    }
}

@media (max-width:783px) {

    .title {
        width: 668px;

        .titleText {
            font-size: 48px;
            line-height: 62px;
        }

        p {
            font-size: 21px;
        }
    }
}

@media (max-width:563px) {

    .title {
        width: 668px;

        .titleText {
            font-size: 54px;
            line-height: 58px;
        }

        p {
            font-size: 17px;
        }
    }
}

@media (max-width:430px) {

    .MainPage {
        max-height: 517px;

        .title {

            max-width: 770px;

            .titleText {

                font-size: 36px;
                line-height: 34px;
            }

            p {
                font-size: 15px;
            }
        }
    }
    .button {
        width: 340px;
    }
}
@media (max-width:350px) {

    .MainPage {
        max-height: 407px;

        .title {

            max-width: 770px;

            .titleText {

                font-size: 20px;
                line-height: 23px;
            }

            p {
                font-size: 14px;
            }
        }
    }
    .button {
        width: 285px;
        span {
            font-size: 19px;
            line-height: 29px;
        }
    }
}
