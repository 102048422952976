.checkWrapper {
    border: 1.50889px solid #393939;
    width: 27px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    display: block;

    svg {
        transform: translateY(6px);
    }
}

.checked {
    display: none;
}