.section {
    font-family: 'Montserrat';
    width: 317px;

    h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;


        color: #393939;
        margin-bottom: 24px;
    }
}

.itemWrapper {}

.item {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;

    /* Text style dark */
    position: relative;
    color: #393939;
    padding-left: 23px;
    margin-top: 16px;
}

.makr {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

}