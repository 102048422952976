.formWrapper {
    padding-top: 120px;
    // height: 686px;
    background-image: url('../../../../../assets/image/background_form.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 120px;
}

.form {
    h3 {
        margin-bottom: 32px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 110%;
        /* or 35px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Text style dark */

        color: #393939;
    }

  
    div {
        &:last-child {
            margin-bottom: 0;
        }
    }

    background-color: #fff;
    width: 654px;
    height: 739px;
    padding: 40px;
    text-align: center;
}

.form>div {
    margin-bottom: 16px;
}

.container {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    padding: 0 15px;
    display: flex;
    justify-content: right;
    overflow: hidden;
}

.button {
    margin-bottom: 32px;
    transform: translateX(-10px);
}

.ckeckboxWrapper {
    display: flex;
    align-items: center;
    justify-content: start;

    .ckeckbox {
        margin-right: 20px;
    }

    span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 145%;
        /* or 29px */
        transform: translateY(-6px);
        text-align: left;

        /* Text style dark */

        color: #393939;

    }
}

@media (max-width: 768px) {
    .container {
        justify-content: center;
    }

    .form {
        height: 800px;
    }
}

@media (max-width: 671px) {
    .form {
        width: 587px;
    }

    .button {
        transform: translateX(0);
        width: 100%;
    }
}

@media (max-width: 425px) {
    .form {
        background-color: #fff;
        width: 398px;
        height: 710px;
        padding: 40px;
        text-align: center;

        h3 {
            margin-bottom: 32px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 110%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #393939;
        }
    }

    .button {
        width: 300px;
        margin-bottom: 16px;
    }

    .ckeckboxWrapper {
        margin-bottom: 0;

        span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            line-height: 145%;
            transform: translateY(-6px);
            text-align: left;
            color: #393939;
        }
    }
}

@media (max-width: 375px) {
    .form {
        background-color: #fff;
        width: 350px;
        height: 640px;
        padding: 19px;
        text-align: center;

        h3 {
            margin-bottom: 11px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 110%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #393939;
        }

        div {
            margin-bottom: 11px;
        }
    }

    .button {
        width: 314px;
        margin-bottom: 16px;
    }

    .ckeckboxWrapper {
        margin-bottom: 0;

        span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #393939;
        }
    }
}

@media (max-width: 321px) {
    .form {
        width: 302px;
        height: 610px;
        padding: 10px;
        text-align: center;


        h3 {
            margin-bottom: 7px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 110%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #393939;
        }
    }

    .button {
        width: 100%;
    }
}