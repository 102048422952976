.blog {
    font-family: 'Montserrat';

    color: #393939;
}

.advancedContent {
    display: flex;
    position: relative;
    -webkit-filter: drop-shadow(23.2131px 76.5582px 80px rgba(0, 0, 0, 0.05)) drop-shadow(15.0455px 49.621px 46.8519px rgba(0, 0, 0, 0.037963)) drop-shadow(8.94135px 29.4891px 25.4815px rgba(0, 0, 0, 0.0303704)) drop-shadow(4.64263px 15.3116px 13px rgba(0, 0, 0, 0.025)) drop-shadow(1.89144px 6.23807px 6.51852px rgba(0, 0, 0, 0.0196296)) drop-shadow(0.429873px 1.41774px 3.14815px rgba(0, 0, 0, 0.012037));
            filter: drop-shadow(23.2131px 76.5582px 80px rgba(0, 0, 0, 0.05)) drop-shadow(15.0455px 49.621px 46.8519px rgba(0, 0, 0, 0.037963)) drop-shadow(8.94135px 29.4891px 25.4815px rgba(0, 0, 0, 0.0303704)) drop-shadow(4.64263px 15.3116px 13px rgba(0, 0, 0, 0.025)) drop-shadow(1.89144px 6.23807px 6.51852px rgba(0, 0, 0, 0.0196296)) drop-shadow(0.429873px 1.41774px 3.14815px rgba(0, 0, 0, 0.012037));
    margin-bottom: 46px;
}

.advancedContentImg {
    min-width: 241px;
    height: auto;

    img {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
    }
}

.advancedContentInfo {
    background-color: #fff;
    padding: 34px 22px 61px 22px;
}

.title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* Text style dark */
    margin-bottom: 8px;
}

.titleDescription {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
}

.listWrapper {
    overflow-y: auto;
}

.listItem {
    position: relative;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    padding-left: 24px;
    margin-bottom: 8px;

    &::before {
        height: 18px;
        width: 13px;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../../../assets/icon/flagBlog.svg');
    }
}

.close {
    position: absolute;
    top: 29px;
    right: 35px;
}

.wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
}

.wrapperCard {
    max-width: 560px;
    max-height: 260px;
    position: relative;
    margin: 0;
}

.cardImage {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: relative;
    background-color: rgb(135, 132, 132);
}

.wrapperCard:hover>.cardDescription {
    opacity: 1;
    transform: translateY(0);
}

.wrapperCard:hover .cardImageTitle {
    opacity: 0;
}

.image {
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
}

.cardImageTitle {
    position: absolute;
    bottom: 24px;
    left: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    transition: all 0.65s;

    /* White */

    color: #FFFFFF;

}

.cardDescription {
    transition: all 0.25s;
    transform: translateY(-30px);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 24px;
}

.cardDescriptionParagraph {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;

    /* White */

    color: #FFFFFF;
    margin-top: 46px;
    margin-bottom: 16px;
    text-align: left;
}

.cardDescriptionReadMore {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    cursor: pointer;
    /* Accent 1 */

    color: #FFCD00;
}

@media (max-width:1024px) {
    .layoutBlog {
        flex-wrap: wrap;
        justify-content: center;
    }

    .wrapper {
        justify-content: center;
    }

    .blog {
        padding-top: 50px;
    }


    .close {
        top: 420px;
    }

    .wrapperCard {
        min-width: 952px;
        min-height: 360px;
    }

    .cardDescriptionParagraph {
        font-size: 23px;
        line-height: 65px;
        margin-top: 54px;
        margin-bottom: 22px;
    }

    .advancedContent {
        flex-wrap: wrap;
    }

    .advancedContentImg {
        width: 100%;
        height: 393px;
    }
}

@media (max-width: 810px) {
    .wrapperCard {
        min-width: 692px;
        min-height: 300px;
    }

    .cardDescriptionParagraph {
        font-size: 18px;
        line-height: 46px;
    }
}

@media (max-width: 425px) {
    .listItem {
        font-size: 14px;
        line-height: 15px;
    }

    .titleDescription {
        font-size: 14px;
    }

    .advancedContentImg {
        height: 243px;
    }

    .close {
        top: 269px;
    }

    .wrapperCard {
        min-width: 0;
        min-height: 0;
        max-width: 390px;
    }

    .cardDescriptionParagraph {
        font-size: 13px;
        line-height: 21px;
        margin-top: 18px;
        margin-bottom: 14px;
    }
}

@media (max-width: 375px) {
    .wrapperCard {
        max-width: 93%;
    }

    .cardDescriptionParagraph {
        font-size: 11px;
    }
}

@media (max-width: 320px) {
    .advancedContentInfo {
        padding: 12px 9px 8px 9px;
    }

    .close {
        top: 259px;
        right: 22px;
    }

    .cardDescriptionParagraph {
        font-size: 9px;
        line-height: 16px;
    }
}