.About {
    color: #393939;
    background-image: url('../../../../../assets/image/bgColorWhite.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 1038px;

    div {
        position: relative;
    }
}

.aboutContainer {
    width: 560px;
    padding-top: 193px;
    overflow-x: hidden;

    h2 {
        font-family: 'Arkhip';
        font-style: normal;
        font-weight: 400;
        font-size: 52px;
        line-height: 62px;
    }
}


.aboutDescription {
    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 140%;
        margin-top: 32px;
    }


}



.oneImg {
    position: absolute;
    top: 111px;
    right: 260px;

    img {
        width: 100%;
        height: auto;
    }
}

.twoImg {
    position: absolute;
    top: 82px;
    right: -400px;
    width: 450px;
    height: auto;

    img {
        width: 100%;
        height: auto;
    }
}

.threeImg {
    position: absolute;
    bottom: -242px;
    right: -250px;
    width: 539px;
}

.container {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    overflow-x: hidden;
    padding: 0 15px;
    height: 100%;
}

@media (min-width: 1201px) {
    .About {
        color: #393939;
        background-image: url('../../../../../assets/image/bgColorWhite.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 1038px;

        div {
            position: relative;

            .oneImg {
                position: absolute;
                top: 111px;
                right: 260px;

                img {
                    width: 100%;
                    height: auto;
                }

                .twoImg {
                    position: absolute;
                    top: 82px;
                    right: -400px;
                    width: 450px;
                    height: auto;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .threeImg {
                    position: absolute;
                    bottom: -242px;
                    right: -250px;
                    width: 539px;
                }


                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }


    .aboutContainer {
        width: 560px;
        padding-top: 193px;
        overflow-x: hidden;

        h2 {
            font-family: 'Arkhip';
            font-style: normal;
            font-weight: 400;
            font-size: 52px;
            line-height: 62px;
        }
    }

    .aboutDescription {
        p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 140%;
            margin-top: 32px;
        }
    }



    .container {
        margin: 0 auto;
        max-width: 1380px;
        min-width: 320px;
        overflow-x: hidden;
        padding: 0 15px;
        height: 100%;
    }
}

@media (max-width: 1200px) {
    .About {
        overflow-y: hidden;
        height: 1440px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        overflow-y: hidden;
        padding: 0 15px;
        height: 1347px;
    }

    .aboutContainer {
        flex: 1;
        padding-left: 44px;
        padding-top: 63px;
        width: 100%;
    }

    .oneImg {
        top: 74px;
        right: -4%;
        bottom: -118px;
    }

    .oneImg>img {
        width: 33%;
    }

    .twoImg {
        top: -651px;
        right: -420px;
        width: 561px;
        height: auto;
    }

    .threeImg {
        bottom: -75px;
        right: -168px;
        width: 1398px;
        top: -869px;

    }

    .threeImg>img {
        width: 50%;
    }
}

@media (max-width: 930px) {
    .oneImg>img {
        width: 30%;
    }

    .twoImg {
        right: -372px;
        top: -651px;
        width: 446px;
    }

    .threeImg {
        top: -786px;
    }
}


@media (max-width: 768px) {

    .oneImg {
        top: 74px;
        right: -4%;
        bottom: -118px;
    }

    .oneImg>img {
        width: 29%;
    }

    .twoImg {
        top: -582px;
        right: -350px;

    }

    .twoImg>img {
        width: 80%;
    }

    .threeImg {

        top: -670px;

    }

    .threeImg>img {
        width: 32%;
    }
}

@media (max-width: 500px) {
    .oneImg {
        display: none;
    }
}

@media (max-width: 425px) {

    .oneImg {
        top: 74px;
        right: -4%;
        bottom: -118px;
    }

    .oneImg>img {
        width: 14%;
    }

    .twoImg {
        top: -253px;
        right: -191px;
    }

    .twoImg>img {
        width: 38%;
    }

    .threeImg {
        top: -360px;
        left: 0;
    }

    .threeImg>img {
        width: 32%;
    }

    .aboutContainer {
        padding-left: 11px;

        p {
            font-size: 20px;
            line-height: 107%;
        }
    }

    .container {
        height: 1038px;
    }

    .About {
        height: 1120px;
    }
}

@media (max-width: 400px) {
    .aboutContainer {
        height: 683px;
    }
}

@media (max-width: 375px) {
    .aboutContainer {
        height: 721px;
    }

    .oneImg {
        top: 74px;
        right: -4%;
        bottom: -118px;
    }

    .oneImg>img {
        width: 11%;
    }

    .twoImg {
        top: -213px;
        right: -150px;
    }

    .twoImg>img {
        width: 44%;
    }

    .threeImg {
        top: -371px;
    }

    .threeImg>img {
        width: 32%;
    }

    .container {
        height: 1089px;
    }

    .About {
        height: 1182px;
    }
}

@media(max-width: 340px) {
    .aboutContainer {
        height: 770px;
    }
}

@media (max-width: 320px) {

    .aboutContainer {
        padding-left: 7px;

        p {
            font-size: 16px;
            line-height: 107%;
            margin-top: 11px;
        }
    }

    .oneImg {
        top: 74px;
        right: -4%;
        bottom: -118px;
    }

    .oneImg>img {
        width: 9%;
    }

    .twoImg {
        top: -163px;
        right: -119px;
    }

    .twoImg>img {
        width: 31%;
    }

    .threeImg {
        top: -263px;
    }

    .threeImg>img {
        width: 24%;
    }

    .container {
        height: 819px;
    }

    .About {
        height: 861px;
    }



}