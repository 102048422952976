.wrapperInput {
    padding: 20px;
    max-width: 554px;
    max-height: 66px;
    background: transparent;
    transition: all 0.75s;
}

.blur {
    border: 1px solid #393939;

}

.focus {
    border: 1px solid #FFCD00;
}

.input {
    width: 100%;
    height: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 145%;
    border: none;
    outline: none;
    background: transparent;


    /* Text style dark */

    color: #393939;

    &::placeholder {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 145%;
        /* or 29px */


        color: rgba(57, 57, 57, 0.5);

    }
}