.notFound {
    background-image: url('../../../assets/image/notFound.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    height: calc(100% - 158px - 280px);
    padding-bottom: 455px;
    padding-top: 255px;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
        font-family: 'Arkhip';
        font-style: normal;
        font-weight: 400;
        font-size: 96px;
        line-height: 115px;

        color: #FFFFFF;

    }

    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        /* identical to box height */


        color: #FFFFFF;

        margin-top: 16px;
        margin-bottom: 52px;
    }
}

@media (max-width: 768px) {
    .title {
        h3 {
            font-size: 25px;
        }
    }
}


@media (max-width: 425px) {
    .notFound {
        padding-bottom: 185px;
        padding-top: 115px;
    }

    .title {
        h1 {
            font-size: 56px;
            line-height: 93px;
        }

        h3 {
            font-size: 14px;
            line-height: 39px;
            margin-top: -19px;
            margin-bottom: 20px;
        }
    }

    .button {
        width: 358px;

    }
}

@media (max-width: 360px) {
    .title {
        h1 {
            font-size: 51px;
            line-height: 93px;
        }

        h3 {
            font-size: 12px;
            line-height: 39px;
        }
    }

        .button {
            width: 278px;

            span {
                font-size: 20px;
            }
    }
}