/* This stylesheet generated by Transfonter (https://transfonter.org) on July 28, 2017 5:02 PM */

@font-face {
    font-family: 'Arkhip';
    src: url('../../src/fonts/Arkhip.eot');
    src: local('../../src/fonts/Arkhip'),
        url('../../src/fonts/Arkhip.eot?#iefix') format('embedded-opentype'),
        url('../../src/fonts/Arkhip.woff') format('woff'),
        url('../../src/fonts/Arkhip.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}