.sectionGallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    // justify-content: space-between;
}

.galleryItems {
    max-width: 50%;
    max-height: 50%;

    img {
        width: 100%;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
    }
}

@media (max-width: 710px) {
    .sectionGallery {
        flex-wrap: wrap;
        justify-content: center;
    }
}

@media (max-width: 600px) {
    .galleryItems {
        max-width: 90%;
        max-height: 90%;

        img {
            width: 100%;
            height: 100%;
            min-width: 100%;
            min-height: 100%;
        }
    }
}