.perfomance {
    background-image: url('../../../../../assets/image/bgColorWhiteArrowTop.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 960px;
    margin-bottom: 357px;
}


.transform {
    transform: translateY(272px);
}

.perfomanceTitle {
    font-family: 'Arkhip';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 62px;
    margin-bottom: 56px;
}



.areaBlock_item {
    width: 420px;
    height: 330px;

    img {
        width: 100%;
        height: 100%;
    }
}

.areaCenter {
    // margin-right: 36px;
    // margin-left: 43px;
    width: 420px;
    height: 700px;

    img {
        width: 100%;
        height: 100%;
    }
}

.cardPhotos {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    overflow: hidden;
}


.buttonWrapper {
    margin-top: 56px;
    text-align: right;
}

.container {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1380px;
    min-width: 320px;
    overflow: hidden;
    padding: 0 10px;
}

.cardPhotos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 40px 40px;
    grid-auto-flow: row;
}

.areaBlock1 {
    grid-area: 1 / 1 / 2 / 2;
}

.areaBlock2 {
    grid-area: 2 / 1 / 3 / 2;
}

.center {
    grid-area: 1 / 2 / 3 / 3;
}

.area3 {
    grid-area: 1 / 3 / 2 / 4;
}

.area4 {
    grid-area: 2 / 3 / 3 / 4;
}

.areaBlock3 {
    grid-area: 1 / 3 / 2 / 4;
}

.areaBlock4 {
    grid-area: 2 / 3 / 3 / 4;
}

@media (max-width: 1024px) {
    .perfomance {
        height: 1720px;
    }

    .cardPhotos {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 20px 20px;
        grid-auto-flow: row;
        grid-template-areas:
            "areaBlock1 areaBlock3"
            "center areaBlock2"
            "center areaBlock4";
    }

    .center {
        grid-area: center;
    }

    .areaBlock1 {
        grid-area: areaBlock1;
    }

    .areaBlock3 {
        grid-area: areaBlock3;
    }

    .areaBlock2 {
        grid-area: areaBlock2;
    }

    .areaBlock4 {
        grid-area: areaBlock4;
    }

    .perfomance {
        margin-bottom: 0;
    }
}

@media (max-width: 900px) {
    .areaCenter {
        // margin-right: 36px;
        // margin-left: 43px;
        width: 372px;
        height: 682px;

    }
}

@media (max-width:800px) {
    .areaBlock_item {
        width: 371px;
        height: 330px;

    }

    .areaCenter {
        // margin-right: 36px;
        // margin-left: 43px;
        width: 372px;
        height: 682px;

    }
}


@media (max-width:465px) {
    .perfomance {
        height: 1088px;
        margin-bottom: 0;
    }

    .perfomanceTitle {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 49px;
    }

    .areaBlock_item {
        width: 188px;
        height: 133px;
    }

    .areaCenter {
        width: 188px;
        height: 289px;
    }
}

@media (max-width:320px) {

    .areaBlock_item {
        width: 139px;
        height: 133px;
    }

    .areaCenter {
        width: 138px;
        height: 289px;
    }
}