.imgCard {
    margin-right: 16px;
}

.rewievs {}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    /* or 34px */


    /* Text style dark */

    color: #393939;
    margin-bottom: 16px;
}

.buttonWrapper {
    display: flex;
    justify-content: end;
    padding: 25px 10px;

}

.inputWrapper {
    padding: 16px;
    width: 100%;
    height: 115px;
    margin: 20px 0;

    /* White */

    background: #FFFFFF;
    /* Text style dark */

    border: 0.5px solid #393939;

    .input {
        width: 100%;
        height: 100%;
        resize: none;
        border: none;
        outline: none;

        &::placeholder {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 300;
            font-size: 20px;
            line-height: 24px;

            color: rgba(57, 57, 57, 0.5);

        }
    }
}

.cardWraper {}

.card {
    padding: 24px;
    padding-right: 37px;
    display: flex;
    background-color: #fff;
    margin: 32px 0;
}

.bottomReviews {
    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;

        /* Text style dark */

        color: #393939;

    }
}

.topReviewsTitle {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;

        /* Text style dark */

        color: #393939;

    }

    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */


        /* Text style dark */

        color: #393939;
    }
}

.bottomReviewsTitle {}

@media (max-width: 768px) {
    .button {
        width: 100%;
    }
    .bottomReviewsTitle {
        p {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

@media (max-width: 600px) {
    .title {
        font-size: 17px;
        line-height: 130%;
    }
}


@media (max-width: 425px) {
    .card {
        flex-direction: column;
        align-items: center;
    }

    .imgCard {
        margin-left: 0;
        margin-bottom: 30px;
    }
}


@media (max-width: 375px) {
    .inputWrapper {
        height: 104px;

    }

}