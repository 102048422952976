@import '/src/styles/config.scss';

.modalParent {
    .root {


        display: none;

        &:last-child {
            display: flex;
        }
    }
}

.root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;

}

.underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 654px;
    height: 720px;
    margin: auto;
    // padding: 50px;
    border-radius: 16px;
    z-index: 90000;

    // overflow-y: auto;
    overflow: hidden;

}

.modal::-webkit-scrollbar {
    display: none;
}

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
}

.close {
    width: 100%;
    height: 60px;
    position: absolute;
    left: 0;
    padding: 0 30px;
    // padding-right: 20px;
    // padding-left: 20px;
    top: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.closeBtn {
    position: absolute;
    top: 3px;
    right: 12px;
}

.body {
    overflow-y: auto;
    min-height: 300px;
    scroll-margin: 10px;
    scrollbar-color: rgb(255, 37, 21) rgb(247, 241, 241);
    scrollbar-width: thin;
    padding-right: 10px;
    overflow: hidden;

    &:after {
        content: '.';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }



    // padding: 2px 16px;
}

.formWrapper {
    padding-top: 120px;
    // height: 686px;
    background-image: url('../../../assets/image/background_form.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 120px;
}

.form {
    h3 {
        margin-bottom: 32px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 110%;
        /* or 35px */

        text-align: center;
        letter-spacing: 0.02em;

        /* Text style dark */

        color: #393939;
    }



    div {
        &:last-child {
            margin-bottom: 0;
        }
    }

    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 40px;
    text-align: center;
}

.form>div {
    margin-bottom: 16px;
}

.container {
    padding: 0 15px;

    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    display: flex;
    justify-content: right;
    overflow: hidden;
}

.button {
    margin-bottom: 32px;
    transform: translateX(-6px);
}

.ckeckboxWrapper {
    display: flex;
    align-items: center;
    justify-content: start;

    .ckeckbox {
        margin-right: 20px;
    }

    span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 145%;
        /* or 29px */
        transform: translateY(-6px);
        text-align: left;

        /* Text style dark */
        color: #393939;

    }
}

@media (max-width: 768px) {
    .container {
        justify-content: center;
    }

    .form {
        height: 800px;
    }
}

@media (max-width: 671px) {
    .form {
        width: 587px;
    }

    .modal {
        width: 577px;
    }

    .button {
        transform: translateX(0);
        width: 100%;
    }
}

@media (max-width: 595px) {
    .modal {
        width: 495px;
    }

    .form {
        padding: 24px;
        width: 500px;

        h3 {
            font-size: 26px;
        }
    }
}

@media (max-width: 510px) {
    .modal {
        width: 400px;
        height: 762px;
    }

    .form {
        width: 400px;
        height: 800px;

        h3 {
            font-size: 22px;
            margin-bottom: 47px;
        }
    }
}

@media (max-width: 425px) {
    .modal {
        height: 695px;
    }

    .form {
        background-color: #fff;
        width: 398px;
        height: 710px;
        padding: 40px 20px;
        text-align: center;

        h3 {
            margin-bottom: 32px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 110%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #393939;
        }
    }

    .button {
        
        margin-bottom: 16px;
    }

    .ckeckboxWrapper {
        margin-bottom: 0;

        span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            line-height: 145%;
            transform: translateY(-6px);
            text-align: left;
            color: #393939;
        }
    }
}

@media (max-width: 400px) {
    .modal {
        height: 628px;
        width: 346px;
    }

    .form {
        background-color: #fff;
        width: 350px;
        height: 640px;
        padding: 19px;
        text-align: center;

        h3 {
            margin-bottom: 11px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 110%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #393939;
        }

        div {
            margin-bottom: 11px;
        }
    }

    .button {
        width: 100%;
        margin-bottom: 16px;
    }

    .ckeckboxWrapper {
        margin-bottom: 0;

        span {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: #393939;
        }
    }
}

@media (max-width: 341px) {
    .modal {
        height: 580px;
        width: 306px;
    }

    .form {
        width: 302px;
        height: 610px;
        padding: 10px;
        text-align: center;


        h3 {
            margin-bottom: 7px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 110%;
            text-align: center;
            letter-spacing: 0.02em;
            color: #393939;
        }
    }

    .button {
        width: 100%;
    }
}