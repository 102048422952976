@import '/src/styles/config.scss';

.modalParent {
    .root {


        display: none;

        &:last-child {
            display: flex;
        }
    }
}

.root {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;

}

.underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: #fff;
    width: 646px;
    height: 395px;
    margin: auto;
    // padding: 50px;
    border-radius: 16px;
    z-index: 90000;

    // overflow-y: auto;
    overflow: hidden;

}

.modal::-webkit-scrollbar {
    display: none;
}

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
}

.close {
    width: 100%;
    height: 60px;
    position: absolute;
    left: 0;
    padding: 0 30px;
    // padding-right: 20px;
    // padding-left: 20px;
    top: 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.closeBtn {
    position: absolute;
    top: 3px;
    right: 12px;
}

.body {
    overflow-y: auto;
    min-height: 300px;
    scroll-margin: 10px;
    scrollbar-color: rgb(255, 37, 21) rgb(247, 241, 241);
    scrollbar-width: thin;
    padding-right: 10px;
    overflow: hidden;

    &:after {
        content: '.';
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }



    // padding: 2px 16px;
}

.close {
    position: absolute;
    top: 31px;
    left: 551px;
}

.iconSuccesMail {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 100px;
    margin-bottom: 32px;
}

.msgSuccesMail {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #515355;

}


@media (max-width: 665px) {
    .modal {
        width: 496px;
        height: 345px;
    }

    .close {
        left: 407px;
    }
}

@media (max-width: 505px) {
    .modal {
        width: 406px;
        height: 272px;
    }

    .close {
        left: 321px;
    }

    .iconSuccesMail {
        margin-top: 40px;
    }

    .body {
        p {
            font-size: 15px;
        }
    }
}

@media (max-width: 425px) {
    .modal {
        width: 336px;
        height: 244px;
    }

    .body {
        P {
            font-size: 12px;
            line-height: 18px;
        }
    }

    .close {
        left: 261px;
    }
}


@media (max-width: 350px) {
    .modal {
        width: 290px;
        height: 244px;
    }

    .close {
        left: 221px;
    }
}