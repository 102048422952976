.cardWrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'Montserrat';
    color: #393939;

}

.card {
    margin-bottom: 32px;
    display: flex;
    height: auto;
    width: 1080px;
    justify-content: space-between;
}

.img {
    height: auto;
    width: 324px;

    img {
        height: 100%;
        width: 100%;
        min-height: 100%;
        min-width: 100%;
    }
}

.right {
    background-color: #fff;
    flex: 1;
    padding: 40px;
    padding-left: 44px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.itemWrapper {
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;

        /* Text style dark */

        margin-bottom: 16px;
    }
}

.item {
    h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
    }

    h6 {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
    }
}

.button {
    display: flex;
    justify-content: end;
}

@media (max-width: 1350px) {
    .card {
        width: 877px;
    }
}

@media (max-width: 1024px) {
    .cardWrapper {
        align-items: center;
        padding-top: 30px;
    }

    .layoutExcursion {
        flex-wrap: wrap;
        justify-content: center;
    }

    .card {
        width: 690px;
        flex-direction: column;
    }

    .img {
        width: 690px;
        height: 400px;
    }
}

@media (max-width: 768px) {
    .card {
        width: 100%;
        flex-direction: column;
    }

    .img {
        height: 480px;
        width: 100%;
    }

    .right {
        max-width: 100%;
    }
}

@media (max-width: 425px) {
    .img {
        height: 329px;
    }

    .right {
        padding: 20px;
    }
}

@media (max-width: 395px) {
    .card {
        width: 350px;
    }
}

@media (max-width: 320px) {
    .card {
        width: 298px;
    }
}