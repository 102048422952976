.container {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    overflow-x: hidden;
    padding: 0 15px;
}

.layout {
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 120px;
    flex-wrap: nowrap;
}

.container {}

.mainSection {}

.subSection {}

.column {
    row-gap: 30px;
    display: flex;
    flex-direction: column;
    width: 320px;
}

.columnTitle {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 140%;
    /* or 28px */


    color: #515355;

}


@media (max-width: 768px) {
    .layout {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .column {

        padding-bottom: 50px;
    }
}

@media (max-width: 425px) {
    .layout {
        display: flex;
        flex-wrap: wrap;
    }

    .column {

        padding-bottom: 50px;
    }
}

@media (max-width: 375px) {
    .layout {
        display: flex;
        flex-wrap: wrap;
    }

    .column {

        padding-bottom: 30px;
    }
}

@media (max-width: 320px) {
    .layout {
        display: flex;
        flex-wrap: wrap;
    }

    .column {

        padding-bottom: 30px;
    }
}