.subscription {
    margin-top: 135px;
    position: relative;
margin-bottom: 134px;
    &::after {
        top: 50%;
        content: "";
        position: absolute;
        background-color: #fff;
        height: 300px;
        width: 100%;
        transform: translateY(-50%);
        z-index: -2;
    }
}


.title {
    text-align: center;

    h3 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
        text-align: center;

        /* Text style dark */

        color: #393939;

        margin-bottom: 24px;
    }
}

.img {
    width: 635px;
    height: 450px;
}

.container {
    margin: 0 auto;
    max-width: 1380px;
    padding: 0 15px;
    min-width: 320px;
    overflow: hidden;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 1024px) {
    .img {
        display: none;
    }
}
@media (max-width: 425px ){
.title {
    h3 {
        font-size: 22px;
    }
}
}
@media (max-width: 375px) {
    .title {
        h3 {
            font-size: 22px;
            line-height: 30px;
        }
    }

    .button {
        width: 326px;

    }
}

@media (max-width: 375px) {
    .title {
        h3 {
            font-size: 16px;
            line-height: 21px;
        }
    }

    .button {
        width: 282px;

    }
}