.WhereTrust {
    padding-top: 162px;
    padding-bottom: 120px;
    color: #393939;
}

.whereTrust_title {
    font-family: 'Arkhip';
    font-style: normal;
    font-weight: 400;
    font-size: 52px;
    line-height: 62px;
    text-align: center;

    margin-bottom: 56px;
}

.card {
    display: flex;
    flex-wrap: wrap;
    row-gap: 47px;
    justify-content: center;
    column-gap: 100px;
}

.card_item {
    width: 360px;
    height: 387px;
    background-color: #fff;
    font-family: 'Montserrat';
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 39px;
        margin: 16px 0;
    }

    p {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
    }
}

.img {
    margin-top: 60px;
    margin-bottom: 16px;
}


.container {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    padding: 0 15px;
    overflow: hidden;
}

@media (max-width: 375px) {
    .card_item {
        width: 327px;
        h4 {
            font-size: 25px;
            line-height: 39px;
        }

        p {
            font-size: 15px;
            line-height: 24px;
        }
    }
}
@media (max-width: 375px) {
    .card_item {
        height: 357px;
        width: 277px;

        h4 {
            font-size: 25px;
            line-height: 39px;
        }

        p {
            font-size: 15px;
            line-height: 24px;
        }
    }
}