.red {
    color: #FD4343;
}

.green {
    color: #2BE31B;
}

.black {
    color: #393939;
}

.calendar {
    color: #393939;
    background-image: url('../../../../../../public/bgCalendar.jpg');
    padding-top: 88px;
    padding-bottom: 97px;
    /* Dark blue */

    // background-color: #fff;

    // border: 1px solid #516695;
}

h1 {
    font-family: 'Arkhip';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 140%;
    text-align: center;
    // margin-bottom: 40px;
}

.selectExcursion {
    padding: 16px 105px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #516695;
}

.arrowRightExcursion {
    transform: rotate(180deg);
}

.container {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    padding: 0 15px;
    overflow-x: hidden;
}

.calendarWrapper {
    margin: 0 auto;
    margin-top: 40px;
    max-width: 865px;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
}

.selectMonth {
    width: 100%;
    /* Sky blue */
    padding: 16px 0;
    padding: 0 105px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: #E6EDF2;
    /* Dark blue */

    border: 1px solid #516695;
}

.header {
    @extend .selectMonth;
    display: table-header-group;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */


    /* Text style dark */
    background: #E6EDF2;
    /* Dark blue */

    border: 1px solid #516695;
    color: #393939;
    padding: 0 105px;
}

th {
    padding: 16px 0;
}


td {
    text-align: center;
    vertical-align: middle;
    padding-top: 12px;
    padding-bottom: 12px;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */




}

.calendarTable {
    background: #fff;
    /* Dark blue */
    width: 100%;
    border: 1px solid #516695;
    border-bottom: none;
}


.titleExcursion {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 110%;

    color: #FFFFFF;

}

.titleMonth {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 110%;
    /* or 26px */
    padding: 16px 0;
    text-align: center;

    /* Dark blue */

    color: #516695;
}

.arrowLeftMonth {
    svg {
        path {
            fill: #516695;
        }
    }
}

.arrowRightMonth {
    transform: rotate(180deg);

    svg {
        path {
            fill: #516695;
        }
    }
}


.saveMark {
    display: flex;
    padding-top: 24px;
    padding-bottom: 19px;
    align-items: center;
    justify-content: space-around;
    background: #fff;
    border: 1px solid #516695;
    border-top: none;
    border-bottom: none;
    gap: 15px;
    flex-wrap: wrap;
}

.saveMarkItem {

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 110%;
    /* identical to box height, or 18px */

    text-align: center;

    /* Text style dark */

    color: #393939;

}

.itemMark {
    width: 40px;
    height: 10px;
    display: block;
}

.greenMark {
    width: 40px;
    height: 10px;
    background: #2BE31B;
}

.redMark {
    background: #FD4343;
}

.blackMark {
    background: #393939;

}

.goToSave {
    width: 100%;
    height: 67px;

    /* Dark blue */

    background: #516695;
    /* Dark blue */

    border: 1px solid #516695;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    /* identical to box height, or 22px */

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* White */

    color: #FFFFFF;
}

.saveMarkContainer {
    display: flex;
    align-items: center;
    gap: 16px;
}

.dop {
    @extend .saveMarkItem
}

.numberLink {
    cursor: pointer;
}


@media (max-width: 906px) {
    .calendarWrapper {
        max-width: 731px;
    }
}

@media (max-width: 780px) {
    .calendarWrapper {
        max-width: 647px;
    }

    h1 {
        font-size: 40px;
        line-height: 105%;
    }
}

@media (max-width: 700px) {
    .saveMark {
        justify-content: space-around;
    }
}

@media (max-width: 677px) {
    .header {
        font-size: 18px;
    }

    .titleMonth {
        font-size: 21px;
    }

    td {
        font-size: 21px;
    }

    h1 {
        font-size: 34px;
    }
}


@media (max-width: 570px) {
    h1 {
        font-size: 28px;
    }

    .calendarWrapper {
        max-width: 500px;
    }
}



@media (max-width: 430px) {
    .calendarWrapper {
        max-width: 399px;
    }

    .titleMonth {
        font-size: 19px;
        margin: 0 15px;
    }

    .selectMonth,
    .header {
        padding: 0 77px;
    }
}