@import '../../../styles/config.scss';

.select {
    // border: 1px solid $color-grey;
    background: transparent;
    min-width: 220px;
    max-width: 554px;
    position: relative;
    padding: 0 42px 0 16px;
    height: 66px;
    border-radius: 8px;
    display: flex;


    &:active {
        box-shadow: none;
    }
}

.label {
    padding: 5px 0px;
    max-width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    font-size: 16px;

}

.modalParent {
    .root {
        display: none;

        &:last-child {
            display: flex;
        }
    }
}

.blur {
    border: 1px solid #393939;

}

.focus {
    border: 1px solid #FFCD00;
}

/**
*
*/
.extraWrapper {
    padding: 2px;
    position: absolute;
    left: 100%;
    top: 0;
}

.extraMenu {
    overflow-y: auto;
    overflow-x: hidden;
    width: 250px;
    max-height: 350px;
    background-color: #fff;
    box-shadow: 0 6px 5px rgba(0, 0, 0, 0.2);
    display: none;

    &:hover {
        display: block;
    }

}


.itemWrapper {
    // padding-right: 25px;
    position: relative;
    box-sizing: border-box;
    flex: 1;
    width: 100%;


    &:not(:last-child) {
        border-bottom: 1px solid gray;
    }

    &:hover {

        // border: 1px solid $color-gray;.
        background-color: #fafafa;

        .extraMenu {
            display: block;
        }
    }
}

.itemIcon {
    transform: rotate(-90deg) translateY(-50%);
    position: absolute;
    right: 10px;
    top: 50%;

}

.options {
    display: flex;
    align-items: center;

    position: relative;
    cursor: pointer;
    font-family: Roboto;
    font-weight: normal;
    font-size: 16px;
    padding: 12px 0;
    margin: 0 16px;
    justify-content: space-between;



    &:not(:first-child) {
        margin-top: 10px;
    }


}

.checkbox {

    margin-right: 12px;

}

.drop {
    width: 100%;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px;
    top: 115%;
    left: 0;
    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    padding: 0 0;
    z-index: 999999;
    box-shadow: 0px 12px 24px rgba(5, 3, 31, 0.2);
    // border-top: 1px solid $color-grey;
}

.oneLevel {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
}

.bgcOdd {
    background-color: gray;
}

.topLabel {
    display: flex;
    align-items: center;
    max-width: 100%;

}

.mark {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: .2s;
}

.activeMark {
    transform: rotateX(180deg) translateY(4px);
}

.placeholder {
    color: #c1c1c1;
    font-weight: 500 !important;
}

.value {
    font-weight: 500;
    overflow-x: auto;
    display: flex;
    white-space: nowrap;
}

.labelTop {
    display: flex;
    margin-bottom: 6px;
    color: black;
    padding: 0;
    justify-content: space-between;
    align-items: center;
    font-family: Montserrat;
}

.label {
    font-weight: 600;
}


//input
.inputBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 8px;
    border-bottom: 1px solid #333;
    height: 44px;
    position: sticky;
    background-color: #fff;
    z-index: 9999;
    top: 0;

    button {
        margin-top: 0;
    }

    input {
        border: none;
        width: 100%;
    }
}

.input {
    font-weight: 500;
}

.disabled {
    color: #c1c1c1;
}

.delete {
    stroke: #c1c1c1;

    &:hover {
        stroke: red;
    }
}


.errorText {
    // position: absolute;
    bottom: -20px;
    color: red;
    left: 0;
    display: flex;
    max-width: 100%;
    text-overflow: ellipsis;
}

.error {
    border: 1px solid red;
    color: red;
}