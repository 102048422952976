@import "../../../styles/config.scss";

.footerBg {
    height: 280px;
    background-color: $bc_color;
    padding: 20px 0;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    padding-bottom: 30px;
}

.footer_item {
    ul {
        .footerList {
            // font-family: 'Montserrat', sans-serif;
        }

        .footerList+.footerList {
            margin-top: 16px;
        }
    }
}

.map {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 140%;
    /* or 28px */


    /* White */

    color: #FFFFFF;
    margin-top: 17px;
}

.number {}

.numberTitle {
    margin-bottom: 16px;
}

.telegram {
    margin-top: 32px;
}

.telegramTitle {
    margin-bottom: 35px;
}

.social {
    ul {
        display: flex;
        align-items: center;

        li+li {
            margin-left: 35px;
        }
    }

    .iconInst {

        path {
            stroke: #fff;
        }
    }

    .iconTelegram {

        path {
            fill: #fff;
        }
    }

    .iconVk {

        path {
            fill: #fff;
        }
    }
}


.container {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    overflow: hidden;
    padding: 0 15px;

}

@media (max-width: 768px) {
    .footerBg {
        height: 100%;
    }

    .container {
        flex-wrap: wrap;
        justify-content: space-around;
        column-gap: 50px;
        row-gap: 50px;
    }
}

@media (max-width: 425px) {
    .footerBg {
        height: 100%;
    }

    .container {
        flex-wrap: wrap;
        justify-content: space-around;
        column-gap: 60px;
        row-gap: 60px;
    }

    .footer_item {
        &:nth-child(1) {
            order: -2;
        }
        &:nth-child(3) {
            order: -1;
        }
        &:nth-child(4) {
            order: -1;
        }
    }
}
@media (max-width: 375px) {
    .footerBg {
        height: 100%;
    }

    .container {
        flex-wrap: wrap;
        justify-content: space-around;
        column-gap: 60px;
        row-gap: 60px;
    }

    .footer_item {
        &:nth-child(1) {
            order: -2;
        }
        &:nth-child(3) {
            order: -1;
        }
        &:nth-child(4) {
            order: -1;
        }
    }
}
@media (max-width: 320px) {
    .footerBg {
        height: 100%;
    }

    .container {
        flex-wrap: wrap;
        justify-content: space-around;
        column-gap: 60px;
        row-gap: 60px;
    }

    .footer_item {
        &:nth-child(1) {
            order: -2;
        }
        &:nth-child(3) {
            order: -1;
        }
        &:nth-child(4) {
            order: -1;
        }
    }
}