@import "../../../styles/config.scss";

.header {
    background-color: $bc_color;
    font-family: 'Montserrat', sans-serif;


}

.headerTop {
    background: $primary_color;
    height: 58px;
    width: 100%;


}

.scrollIcon {
    position: fixed;
    z-index: 100;
    transition: all 2s;
}

.vis {
    opacity: 1;
    bottom: 80px;
    right: 80px;
}

.notVis {
    opacity: 0;
    bottom: -500px;
    right: -500px;
}




.headerContainer {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.location {
    display: flex;
    align-items: center;

    span {
        margin-left: 10px;
    }
}

.social {
    ul {
        display: flex;

        li {
            list-style: none;
        }

        li+li {
            margin-left: 40px;
        }
    }
}

.phone {
    display: flex;
    align-items: center;

    span {
        margin-left: 8px;
    }

    path {
        fill: #393939;
        stroke: #393939;
    }
}

.headerBottom {


    div {
        display: flex;
        justify-content: space-between;
        height: 100px;
        align-items: center;





    }
}

.logo {
    display: flex;
    align-items: center;
    background-color: transparent;
}

.nav {
    ul {
        display: flex;

        li {
            font-family: 'Montserrat';
            font-style: normal;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;

        }

        li+li {
            margin-left: 68px;
        }
    }
}

.container {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    overflow: hidden;
    padding: 0 15px;

}

.burgerBtn {
    display: none;
    z-index: 5;
}

.headerAdaptive {
    position: fixed;
    top: 0;
    right: -50px;
    width: 0;
    background: $bc_color;
    opacity: 0;
    transition: all 0.5s;
    padding-left: 15px;
    padding-bottom: 20px;
}

.activeAdaptiveHeader {
    width: 210px;
    right: 0;
    opacity: 1;
    z-index: 3;

}

.logoAdaptive {
    padding-top: 43px;
    padding-bottom: 40px;
}

.listHeaderAdaptive {
    ul {
        li {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height */

            text-transform: uppercase;

            /* White */

            color: #FFFFFF;
            margin-bottom: 32px;
        }
    }
}

.socialAdaptive {
    ul {
        display: flex;

        li {
            list-style: none;

            a {

                svg {
                    fill: #fff;
                    stroke: #fff;

                    path {
                        fill: #fff;
                        stroke: #fff;
                    }
                }
            }
        }

        li+li {
            margin-left: 40px;
        }
    }
}



@media (max-width: 1229px) {
    .nav {
        ul {
            li+li {
                margin-left: 43px;
            }
        }
    }
}

@media (max-width: 1086px) {
    .nav {
        ul {
            li+li {
                margin-left: 30px;
            }
        }
    }
}

@media (max-width: 1024px) {
    .nav {
        overflow-x: scroll;
    }
}

@media (max-width: 1000px) {
    .burgerBtn {
        display: block;
        margin-right: 10px;
        position: absolute;
        right: 38px;
        top: 50%;
        transform: translateY(-50%)
    }

    .social {
        display: none;
    }

    .headerBottom {
        display: none;
    }

    .headerTop {
        background-color: $bc_color;
    }

    .headerContainer {
        padding-right: 120px;
        color: #FFFFFF;
    }

    .phone {
        path {
            fill: #fff;
            stroke: #fff;
        }
    }

    .location {
        path {
            fill: #fff;
            stroke: #fff;
        }
    }


}

@media (max-width: 700px) {
    .headerContainer {
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        padding-left: 15px;
    }

    .headerTop {
        height: 80px;
    }
}

@media(max-width: 600px) {
    .vis {
        opacity: 1;
        bottom: 80px;
        right: 40px;
    }
    
}

@media (max-width: 550px) {
    .headerContainer {
        padding: 15px;
    }

    .location,
    .phone {
        svg {
            width: 15px;
            height: 15px;
        }

        span {
            font-size: 11px;
        }
    }
}

@media (max-width:420px) {
    .burgerBtn {
        right: 5px;
    }
}

@media (max-width: 355px) {
    .burgerBtn {
        right: -15px;
    }
}

@media (max-width: 330px) {
    .burgerBtn {
        right: 10px;
    }
}