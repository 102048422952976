.reviews {
    h1 {
        font-family: 'Arkhip';
        font-style: normal;
        font-weight: 400;
        font-size: 52px;
        line-height: 62px;

        /* Text style dark */

        color: #393939;
        margin-bottom: 56px;
    }
}

.itemWrapper {
    display: flex;
    column-gap: 52px;
    margin-bottom: 56px;
}

.item {
    background-color: #fff;
    padding: 30px;
    width: 430px;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    font-family: 'Montserrat';

}

.img {
    height: 55px;
    width: 55px;
    margin-right: 16px;

    img {
        width: 55px;
        height: 55px;
    }
}

.title {

    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    /* or 29px */


    /* Text style dark */

    color: #393939;

}

.reviews {
    margin-top: 16px;
    font-family: 'Montserrat';

    p {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 145%;
        /* or 29px */


        /* Text style dark */

        color: #393939;
        text-overflow: ellipsis;

    }
}

.button {
    text-align: right;
}

.container {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1380px;
    min-width: 320px;
    overflow: hidden;
}

@media (max-width: 1024px) {
    .itemWrapper {
        flex-wrap: wrap;
        row-gap: 24px;
        justify-content: center;
    }

    .reviews {
        h1 {
            margin-left: 35px;
            font-size: 30px;
            line-height: 50px;
        }
    }

    .button {
        text-align: center;
    }
}

@media (max-width: 425px) {
    .reviews {
        h1 {
            font-size: 39px;
            line-height: 45px;
            margin-bottom: 13px;
        }
    }

    .item {
        width: 386px;
    }
    .buttonElement {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width: 375px) {
    .reviews {
        h1 {
            font-size: 26px;
            margin-top: 10px;
            margin-left: 10px;
        }
    }

    .item {
        width: 334px;
    }

    .title {
        font-weight: 600;
        font-size: 24px;
    }

    .reviews {
        p {
            font-size: 14px;
            line-height: 145%;
        }
    }

    .button {
        text-align: center;
        width: 227px;
    }

    .buttonElement {
        width: 100%;
        margin-left: 0;
    }
}