.services {
}

.itemWrapper {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 40px;
  justify-content: start;

}

.itemCustom {
  background: #fff;
  width: 445px;
  box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.4);
  color: #516595;
  padding: 20px;
  font-weight: 500;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item {
  img {
  width: 500px;
  }
}
@media (max-width: 1024px) {
  .itemCustom {
    width: 445px;
  }
  .item {
    width: 500px;

    a {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

;

  .itemWrapper {
    justify-content: center;
  }

  .item {
    width: 445px;

    &:first-child {
      order: 3;
    }
  }

}


@media (max-width: 768px) {
  .itemWrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0;
    row-gap: 40px;
    justify-content: center;
  }
}

@media (max-width: 425px) {
  .itemCustom {
    width: 330px;}
  .item {
    width: 330px;

    a {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

;
}

@media (max-width: 320px) {
  .itemCustom {
    width: 270px;}
  .item {
    width: 270px;

    a {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

;
}
