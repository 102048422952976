.itemWrapper {
    color: #393939;
    font-family: 'Montserrat';
    text-align: left;

    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 24px;
        margin-top: 32px;
        /* Text style dark */

    }

    p {
        padding-left: 28px;
        margin-top: 16px;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 140%;
        position: relative;


        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            transform: translateY(20%);
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #FFCD00;
        }
    }
}

.bold {
    font-weight: 500;
}


@media (max-width: 470px) {
    .itemWrapper {
        p {
            margin-top: 9px;
            font-size: 17px;
            line-height: 131%;
        }

        h3 {
            font-size: 21px;
            line-height: 26px;
            margin-bottom: 9px;
            margin-top: 20px;
        }
    }
}


@media (max-width: 350px) {
    .itemWrapper {
        p {
            margin-top: 9px;
            font-size: 13px;
            line-height: 131%;
        }

        h3 {
            font-size: 16px;
            line-height: 23px;
            margin-bottom: 9px;
            margin-top: 20px;
        }
    } 
}