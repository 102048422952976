.container {
    margin: 0 auto;
    max-width: 1380px;
    min-width: 320px;
    overflow-x: hidden;
    padding: 0 15px;
}

.about {
    display: flex;
    margin-top: 94px;
    margin-bottom: 141px;
    height: 889px;
}

.title {
    font-family: 'Montserrat';
    color: #393939;

    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        margin-top: 56px;
    }

    p {
        text-align: left;
        margin-top: 16px;
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 140%;
    }
}

.imgOne {
    width: 440px;
    height: 550px;
    min-width: 440px;
    min-height: 550px;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 47px 80px rgba(0, 0, 0, 0.07)) drop-shadow(0px 19.6355px 33.4221px rgba(0, 0, 0, 0.0503198)) drop-shadow(0px 10.4981px 17.869px rgba(0, 0, 0, 0.0417275)) drop-shadow(0px 5.88513px 10.0172px rgba(0, 0, 0, 0.035)) drop-shadow(0px 3.12555px 5.32008px rgba(0, 0, 0, 0.0282725)) drop-shadow(0px 1.30061px 2.21381px rgba(0, 0, 0, 0.0196802));

}

.imgTwo {
    position: absolute;
    left: -160px;
    bottom: -80px;
    width: 345px;
    height: 300px;


}

.wrapperImg {
    position: relative;
    padding-top: 110px;



}

.topTitle {
    max-width: 800px;
}

.bottomTitle {
    max-width: 600px;

    p {
        font-style: italic;
    }
}

.topTitle {
    p {
        font-style: italic;
    }
}

@media (max-width: 1024px) {
    .about {
        display: flex;
        flex-wrap: wrap;
        margin-top: -17px;
    }

    .topTitle {
        max-width: 100%;
    }

    .bottomTitle {
        max-width: 100%;
    }

    .wrapperImg {
        padding-top: 5px;
        height: 415px;
    }

    .imgOne {
        position: absolute;
        top: 45px;
        right: -716px;
    }

    .imgTwo {
        left: 510px;
        bottom: -210px;
    }
}

@media (max-width: 1011px) {
    .about {
        height: 1218px;
        overflow: hidden;
    }

    .imgOne {
        top: -25px;
        right: -716px;
    }

    .imgTwo {
        left: 510px;
        bottom: -147px;
    }

}

@media (max-width: 768px) {
    .imgOne {
        right: -526px;
    }

    .imgTwo {
        left: 290px;
    }
}

@media (max-width: 682px) {
    .about {
        height: 1373px;
        overflow: hidden;
    }
}

@media (max-width: 500px) {
    .imgContainer {
        display: none;
    }
    .about {
        height: 100%;
    }
}

@media (max-width: 425px) {
    .imgOne {
        right: 15px;
    }

    .imgTwo {
        left: 80px;
    }

    .title {
        h3 {
            font-size: 19px;
            line-height: 34px;
        }

        p {
            text-align: left;
            margin-top: 6px;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 114%;
        }
    }

    .imgContainer {
        overflow: hidden;
        height: 532px;
        width: 100%;
    }


}

@media (max-width: 375px) {
    .wrapperImg {
        height: 522px;
        overflow: hidden;
    }

    .imgOne {
        right: -32px;
        top: 47px;
        min-width: 381px;
        min-height: 550px;
        width: 90%;
    }

    .imgTwo {
        left: 30px;
        bottom: -100px;
    }
}