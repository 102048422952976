.contain {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;

    /* Text style dark */

    color: #393939;
}

.button {
    width: 398px;
    height: 69px;
    outline: none;
    border: none;
    /* Accent 1 */
    transition: all 0.5s;

    &:hover {
        box-shadow: 0px 36px 80px rgba(0, 0, 0, 0.07), 0px 15.0399px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 8.04107px 17.869px rgba(0, 0, 0, 0.0417275), 0px 4.50776px 10.0172px rgba(0, 0, 0, 0.035), 0px 2.39404px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.996212px 2.21381px rgba(0, 0, 0, 0.0196802);
    }
}

.yellow {
    background: #FFCD00;

}

.small {
    width: 319px;
}

.veryBig {
    width: 554px;
}

.disabled {
    background: #C4C4C4;
}

.primary {
    width: 100%;
}